import React from 'react';
import { BODY2, CAPTION, H3 } from '@tuunetech/tuune-components';
import { Control, FieldErrors } from 'react-hook-form';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { OAuthProvider } from 'modules/Auth/constants/socialAuth';
import { SocialAuth } from 'modules/Auth/components/SocialAuth';
import { SocialAuthType } from 'modules/Auth/types/socialAuth';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { MarketingOptions } from '../MarketingOptions';
import { PhoneInput } from 'shared/PhoneInput';
import { getDefaultCountry } from 'utils/helpers';
import { TuuneInput } from 'shared';

export type AccountInfoProps = {
  control: Control<Partial<AccountInfoFormData>>;
  handleSubmit: () => void;
  handleSocialAuthClick: (oAuthProvider: OAuthProvider) => void;
  errors: FieldErrors<Partial<AccountInfoFormData>>;
};

export const AccountInfo: React.FC<AccountInfoProps> = ({
  control,
  handleSubmit,
  handleSocialAuthClick,
  errors,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" marginBottom="64px">
        <H3 $marginTop={isMd ? 40 : 32} $marginBottom={isMd ? 40 : 24}>
          First, let’s create your account.
        </H3>
        <SocialAuth
          authType={SocialAuthType.SignUp}
          onAuthButtonClick={(oAuthProvider) =>
            handleSocialAuthClick(oAuthProvider)
          }
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="24px"
        >
          <BODY2>OR</BODY2>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="16px"
          marginBottom="40px"
          width="100%"
        >
          <TuuneInput
            name="firstName"
            placeholder="First name"
            control={control}
            fullWidth
            autoComplete="given-name"
            helperText={errors.firstName?.message}
            error={!!errors.firstName}
          />
          <TuuneInput
            name="lastName"
            placeholder="Last name"
            control={control}
            fullWidth
            autoComplete="family-name"
            helperText={errors.lastName?.message}
            error={!!errors.lastName}
          />
          <CAPTION color="textSecondary">
            We need your real, legal name in order to show you any
            recommendations and/or test results.
          </CAPTION>
          <TuuneInput
            name="email"
            placeholder="Email"
            control={control}
            fullWidth
            autoComplete="email"
            helperText={errors.email?.message}
            error={!!errors.email}
          />
          <PhoneInput
            control={control}
            helperText={errors.phone?.message}
            error={!!errors.phone}
            countryObj={getDefaultCountry()}
            fullWidth
          />
        </Box>
        <MarketingOptions control={control} />
      </Box>
    </form>
  );
};
