import React, { useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountInfo } from 'modules/Questionnaire/components/GetStarted';
import {
  AccountInfoFormData,
  ClinicAppointmentFormData,
} from 'modules/Questionnaire/types';
import {
  accountInfoSchema,
  clinicAccountInfoSchema,
  MARKETING,
} from 'modules/Questionnaire/constants';
import { getDefaultCountry } from 'utils/helpers';
import {
  preSignUpRequest,
  setGetStartedAccountInfo,
  setGetStartedOAuthProvider,
  setClinicGetStartedAppointment,
  setGetStartedMarketingAndStateInfo,
} from '../actions';
import { useAnalytics } from 'utils/analytics';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  CTA_POSITION,
  CTA_TEXT,
} from 'utils/analytics/constants';

import { OAuthProvider } from 'modules/Auth/constants/socialAuth';
import { getStartedData } from '../selectors';
import { ClinicAccountInfo } from '../components/ClinicGetStarted/ClinicAccountInfo';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import { NavigationButtonsFooter } from 'shared/NavigationButtonsFooter';

export type AccountInfoFormProps = {
  handleSubmit: () => void;
  clinicAuthConfig?: ClinicAuthConfig;
};

export const AccountInfoFormContainer: React.FC<AccountInfoFormProps> = ({
  handleSubmit: handleNext,
  clinicAuthConfig,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { clinicDetails } = useSelector(getStartedData);
  const defaultCountry = useMemo(() => {
    return getDefaultCountry();
  }, []);
  const formRef = useRef<HTMLDivElement | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<AccountInfoFormData & ClinicAppointmentFormData>>({
    defaultValues: {
      [MARKETING.researchPreference]: true,
      [MARKETING.marketingPreference]: true,
    },
    resolver: yupResolver(
      clinicAuthConfig ? clinicAccountInfoSchema : accountInfoSchema,
    ),
    mode: 'onTouched',
  });

  const submit = useCallback(
    ({
      firstName,
      lastName,
      email,
      state,
      phone,
      clinicianId,
      ...optionAnswers
    }) => {
      const marketingOptions = new Set();
      if (optionAnswers[MARKETING.marketingPreference])
        marketingOptions.add(MARKETING.marketingPreference);
      if (optionAnswers[MARKETING.researchPreference])
        marketingOptions.add(MARKETING.researchPreference);

      const userData = {
        firstName,
        lastName,
        email,
      };
      const marketingAndStateInfo = {
        state,
        country: defaultCountry?.name,
        phone: `${getDefaultCountry().phoneCode} ${phone}`,
        marketingOptions,
      };

      analytics.track(ANALYTICS_EVENT.CTA, {
        category: ANALYTICS_CATEGORY.GET_STARTED,
        cta: CTA_TEXT.GET_STARTED,
        ctaPosition: CTA_POSITION.GET_STARTED_PAGE,
      });

      // Make sure we clear the OAuthFlow from redux
      dispatch(setGetStartedOAuthProvider(undefined));
      dispatch(setGetStartedAccountInfo(userData));
      dispatch(setGetStartedMarketingAndStateInfo(marketingAndStateInfo));

      const appointmentDateNew = new Date();
      if (clinicAuthConfig) {
        // Clinic signup flow
        dispatch(
          setClinicGetStartedAppointment(
            clinicianId,
            // Format date using yyyy-mm-dd format
            appointmentDateNew.toISOString().split('T')[0],
          ),
        );
      } else {
        if (marketingOptions.size !== 0) {
          // Email signup flow - register to klaviyo
          dispatch(
            preSignUpRequest({
              ...userData,
              ...marketingAndStateInfo,
            }),
          );
        }
      }

      handleNext();
    },
    [dispatch, handleNext, analytics, defaultCountry?.name, clinicAuthConfig],
  );

  const handleError = useCallback(() => {
    if (formRef?.current) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, []);

  const handleSocialAuthClick = useCallback(
    (oAuthProvider: OAuthProvider) => {
      analytics.track(ANALYTICS_EVENT.CTA, {
        category: ANALYTICS_CATEGORY.GET_STARTED,
        cta: `${CTA_TEXT.SOCIAL_SIGN_UP} ${oAuthProvider}`,
        ctaPosition: CTA_POSITION.GET_STARTED_PAGE,
      });

      dispatch(setGetStartedOAuthProvider(oAuthProvider));
      handleNext();
    },
    [dispatch, handleNext, analytics],
  );

  const accountInfoComponent = clinicAuthConfig ? (
    <ClinicAccountInfo
      control={control}
      errors={errors}
      handleSubmit={handleSubmit(submit)}
      clinicians={clinicDetails?.clinicians}
    />
  ) : (
    <AccountInfo
      control={control}
      errors={errors}
      handleSubmit={handleSubmit(submit)}
      handleSocialAuthClick={handleSocialAuthClick}
    />
  );

  return (
    <>
      <div ref={formRef}>{accountInfoComponent}</div>
      <NavigationButtonsFooter
        handleContinue={handleSubmit(submit, handleError)}
      />
    </>
  );
};
