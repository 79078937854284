import React from 'react';
import { Container } from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import { AccountInfoFormContainer } from 'modules/Questionnaire/containers/AccountInfoFormContainer';
import { ClinicAuthConfig } from 'modules/Auth/types/clinicAuth';
import { ClinicLogoImg, Title } from './styles';

export type LandingProps = {
  handleSubmit: () => void;
  clinicAuthConfig: ClinicAuthConfig;
};

export const ClinicLanding: React.FC<LandingProps> = ({
  handleSubmit,
  clinicAuthConfig,
}) => {
  return (
    <Container>
      <Box
        width="630px"
        maxWidth="100%"
        marginX="auto"
        display="flex"
        flexDirection="column"
        gridRowGap="40px"
      >
        <ClinicLogoImg src={clinicAuthConfig.logoImg} />
        <Title>
          Find the birth control that fits <i>you</i> best
        </Title>

        <AccountInfoFormContainer
          handleSubmit={handleSubmit}
          clinicAuthConfig={clinicAuthConfig}
        />
      </Box>
    </Container>
  );
};
