const Routes = {
  HOME: '/',
  LOGIN: '/login',
  OAUTH_REDIRECT: '/oauth-redirect',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  PUBLIC_VERIFY_EMAIL: '/verify',
  RESEND_EMAIL_CUSTOM: '/resend-email',
  ACCOUNT: '/account',
  HELP: '/help',
  REGULATION: '/regulation',
  EDIT: '/edit',
  DELETE: '/delete',
  DELETE_CONFIRM: '/delete-confirm',
  QUESTIONNAIRE: '/questions',
  GET_STARTED: '/get-started',
  RESULTS: '/results',
  REVIEW_RESULTS: '/results-review',
  RESULTS_CLINICAL_REVIEW: '/results-clinical-review',
  DEALBREAKER: '/dealbreaker',
  ACTIVATE: '/activate',
  START_HA: '/start',
  FEEDBACK: '/feedback',
  CHECKOUT: '/checkout',
  PREVIEW: '/preview',
  ORDER_SUCCESS: '/order-success',
  PRESCRIPTION_CONFIRMATION: '/prescription-confirmation',
  PHARMACY: '/pharmacy',
  STUDY_UNLOCK: '/study-unlock',
  HT_ADDRESS: '/hormone-test-address',
  HT_ORDER_CONFIRMED: '/hormone-test-order-confirmed',
  TERMS: '/terms',
  LEGAL_NAME: '/legal-name',
  CLINICS_GET_STARTED: '/clinics-get-started',
  // Legacy clinics signup URLs that will get redirected to the new route
  CCW_GET_STARTED: '/ccw',
  CLINIC_DEMO_GET_STARTED: '/demo',
  WD_GET_STARTED: '/wd',
};

export default Routes;
