import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import { AppHeader } from '@tuunetech/tuune-components';
import { IntroContainer } from 'modules/Results/containers/IntroContainer';
import { SymptomSectionContainer } from 'modules/Results/containers/SymptomSectionContainer';
import { RecommendationContainer } from 'modules/Results/containers/RecommendationContainer';
import { FlagsContainer } from 'modules/Results/containers/FlagsContainer';
import { ContraceptiveType } from 'modules/Results/constants/recommendations';
import { useBaseNavButtons } from 'utils/hooks/useBaseNavButtons';
import { Version } from '../Version';
import {
  getAssessmentData,
  getGreenFlags,
  getOrangeFlags,
  getRedFlags,
  getReportVersions,
  getReportGenerationDate,
} from '../../selectors';
import RecommendationFilterContainer from 'modules/Results/containers/RecommendationFilterContainer';
import { useAnalyticsTimeSpentOnPage } from 'utils/analytics/hooks/useAnalyticsTimeSpentOnPage';
import {
  ANALYTICS_CATEGORY,
  ANALYTICS_EVENT,
  REPORT_STAGE_STATUS_MAPPING,
} from 'utils/analytics/constants';
import { getClinicInfo, getReportStageStatus } from 'modules/Account/selectors';
import ShareReport from '../ShareReport';
import {
  feedbackOriginReportPlus,
  negativeSentimentsReportPlus,
  positiveSentimentsReportPlus,
  sectionTitleReportPlus,
} from 'shared/ReportFeedbackSection/constants';
import { ReportFeedbackSection, ReportContentWrapper, Page } from 'shared';
import { Theme, useMediaQuery } from '@material-ui/core';
import { ClinicIntro } from '../Intro/ClinicIntro';

const ResultsLayout: React.FC = () => {
  const reportStatus = useSelector(getReportStageStatus);

  const [contraceptiveTypesFilter, setContraceptiveTypesFilter] = useState<
    ContraceptiveType[]
  >([]);

  useAnalyticsTimeSpentOnPage(
    Routes.RESULTS,
    ANALYTICS_EVENT.RECOMMENDATION_TIME_SPENT_ON_PAGE,
    {
      category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      reportStageStatus: reportStatus
        ? REPORT_STAGE_STATUS_MAPPING[reportStatus]
        : '',
    },
  );

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  const onRecommendationFilterCallback = useCallback(
    (contraceptiveTypes: ContraceptiveType[]) => {
      setContraceptiveTypesFilter(contraceptiveTypes);
    },
    [setContraceptiveTypesFilter],
  );

  const assessmentData = useSelector(getAssessmentData);
  const reportVersion = useSelector(getReportVersions);
  const generationDate = useSelector(getReportGenerationDate);
  const redFlags = useSelector(getRedFlags);
  const greenFlags = useSelector(getGreenFlags);
  const orangeFlags = useSelector(getOrangeFlags);

  const isRenderSymptoms = !![
    ...assessmentData.symptomsUnknown,
    ...assessmentData.symptomsHormonal,
    ...assessmentData.conditions,
  ].length;

  const isRenderFlags = !![...orangeFlags, ...greenFlags, ...redFlags].length;

  const headerButtons = useBaseNavButtons();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const clinicInfo = useSelector(getClinicInfo);

  return (
    <Page removePadding={!isDesktop}>
      <AppHeader
        pageTitle="Wellbeing Report"
        buttons={[
          headerButtons.contactUs,
          headerButtons.account,
          headerButtons.logout,
        ]}
      />

      {/* 1. INTRO */}
      {clinicInfo ? <ClinicIntro /> : <IntroContainer />}

      {/* 3. RECOMMENDATION FILTER & INELIGIBILITY DETAILS */}
      <ReportContentWrapper>
        <RecommendationFilterContainer
          handleRecommendationFilterChange={onRecommendationFilterCallback}
        />
      </ReportContentWrapper>

      {/* 4. RECOMMENDATION */}
      <RecommendationContainer
        contraceptiveTypesFilter={contraceptiveTypesFilter}
      />

      {/* 5.CLINIC QUESTIONS TO ASK */}
      {/* clinicInfo && (
        <ReportContentWrapper>
          <QuestionsForAppointment />
        </ReportContentWrapper>
      ) */}

      {/* 5.NON_CLINIC SHARE REPORT */}
      {!clinicInfo && (
        <ReportContentWrapper addTopDivider>
          <ShareReport />
        </ReportContentWrapper>
      )}

      {/* 6. SYMPTOMS */}
      {isRenderSymptoms && (
        <ReportContentWrapper addTopDivider addBottomDivider>
          <SymptomSectionContainer assessmentData={assessmentData} />
        </ReportContentWrapper>
      )}

      {/* 7. MONITORING */}

      {/* 8. FLAGS */}
      {isRenderFlags && (
        <ReportContentWrapper
          addTopDivider={!isRenderSymptoms}
          addBottomDivider
        >
          <FlagsContainer />
        </ReportContentWrapper>
      )}

      {/* 9. FEEDBACK */}
      <ReportContentWrapper addBottomDivider={!!reportVersion}>
        <ReportFeedbackSection
          title={sectionTitleReportPlus}
          positiveSentiments={positiveSentimentsReportPlus}
          negativeSentiments={negativeSentimentsReportPlus}
          feedbackOrigin={feedbackOriginReportPlus}
        />
      </ReportContentWrapper>

      {/* 10. VERSIONS */}
      {reportVersion && (
        <ReportContentWrapper>
          <Version version={reportVersion} generationDate={generationDate} />
        </ReportContentWrapper>
      )}
    </Page>
  );
};

export default ResultsLayout;
