import React from 'react';
import { BODY1, CAPTION, H3, Select } from '@tuunetech/tuune-components';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  AccountInfoFormData,
  ClinicAppointmentFormData,
} from 'modules/Questionnaire/types';

import { Box, MenuItem } from '@material-ui/core';
import { TuuneInput } from 'shared';
import { Clinician } from 'modules/Auth/types/clinicAuth';
import { MarketingOptions } from '../../GetStarted/MarketingOptions';

export type ClinicAccountInfoProps = {
  clinicians?: Clinician[];
  control: Control<Partial<AccountInfoFormData & ClinicAppointmentFormData>>;
  handleSubmit: () => void;
  errors: FieldErrors<Partial<AccountInfoFormData & ClinicAppointmentFormData>>;
};

export const ClinicAccountInfo: React.FC<ClinicAccountInfoProps> = ({
  control,
  handleSubmit,
  errors,
  clinicians,
}) => {
  const renderClinicianSelectValue = (selected: unknown) => {
    if (!selected) {
      return <BODY1 color="textSecondary">Your clinician</BODY1>;
    } else {
      const clinicianName = clinicians?.find(
        (clinician) => clinician.id === selected,
      )?.fullName;
      return <BODY1>{clinicianName}</BODY1>;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" marginBottom="64px">
        <H3 $marginTop={24} $marginBottom={24}>
          Create your account.
        </H3>

        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="16px"
          width="100%"
        >
          <Box display="flex" flexDirection="column" gridRowGap="8px">
            <TuuneInput
              name="firstName"
              placeholder="First name"
              control={control}
              fullWidth
              autoComplete="given-name"
              helperText={errors.firstName?.message}
              error={!!errors.firstName}
            />
            <CAPTION color="textSecondary">
              Please enter your legal first name
            </CAPTION>
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap="8px">
            <TuuneInput
              name="lastName"
              placeholder="Last name"
              control={control}
              fullWidth
              autoComplete="family-name"
              helperText={errors.lastName?.message}
              error={!!errors.lastName}
            />
            <CAPTION color="textSecondary">
              Please enter your legal last name
            </CAPTION>
          </Box>
          <TuuneInput
            name="email"
            placeholder="Email"
            control={control}
            fullWidth
            autoComplete="email"
            helperText={errors.email?.message}
            error={!!errors.email}
          />
          <Box display="flex" flexDirection="column" gridRowGap="8px">
            <input type="hidden" name="appointmentDate" value="01/01/2025" />
          </Box>
          <Controller
            name="clinicianId"
            control={control}
            defaultValue={undefined}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Select
                  fullWidth
                  variant="outlined"
                  displayEmpty
                  renderValue={renderClinicianSelectValue}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  helperText={errors.clinicianId?.message}
                  error={!!errors.clinicianId}
                >
                  {clinicians?.map(({ id, fullName }) => (
                    <MenuItem key={id} value={id}>
                      {fullName}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
          <MarketingOptions control={control} />
        </Box>
      </Box>
    </form>
  );
};
