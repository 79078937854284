import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  BODY1,
  Container,
  GridContainer,
  GridItem,
  H5,
} from '@tuunetech/tuune-components';
import { Link } from 'shared';
import { Divider, Banner } from './styles';
import { useSelector } from 'react-redux';
import { getClinicInfo } from 'modules/Account/selectors';

const ReviewResults: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const clinicInfo = useSelector(getClinicInfo);

  return (
    <Container>
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={10} lg={7}>
          <Banner>
            <H5 align="center">Your results are being reviewed.</H5>
          </Banner>
        </GridItem>

        {!clinicInfo && (
          <GridItem xs={12} md={10} lg={7}>
            <Divider />
            <BODY1 $marginBottom={isXs ? 80 : 40}>
              If you haven&#39;t received your report within 2 business days,
              please get in touch at{' '}
              <Link
                variant="body1"
                href="mailto:help@tuune.com"
                target="_blank"
              >
                help@tuune.com
              </Link>
              .
            </BODY1>
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};

export default ReviewResults;
